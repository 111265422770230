<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb />
        </b-col>

        <action-button />
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <b-card class="pb-0 pb-sm-64">
        <b-row>
          <b-col cols="12">
            <h1 class="mb-4">Simples &amp; Prático - Planos Flexíveis</h1>
            <p class="hp-p1-body mb-0">
              Obtenha versões ilimitadas com nossos planos detalhados
            </p>
          </b-col>

          <b-col cols="12">
            <billed-switch :switchChecked="switchChecked" @switchChange="switchChange" />

            <item :switchChecked="switchChecked" :data="data" />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";

import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import ActionButton from "@/layouts/components/content/action-button/ActionButton.vue";
import Item from "./Item.vue";
import BilledSwitch from "./BilledSwitch.vue";

export default {
  data() {
    return {
      switchChecked: true,
      data: [
        {
          title: "Básico",
          subTitle: "Explicação simples",
          priceYearly: "R$ 199",
          priceMonthly: "R$ 59",
          billedYearly: "Cobrado anualmente",
          billedMonthly: "Faturado mensalmente",
          special: false,
          best: false,
          button: "Experimente Grátis",
          list: ["10gb espaço", "1 utilizador", "1 equipe"],
        },
        {
          title: "Iniciante",
          subTitle: "Explicação simples",
          priceYearly: "R$ 199",
          priceMonthly: "R$ 59",
          billedYearly: "Cobrado anualmente",
          billedMonthly: "Faturado mensalmente",
          special: false,
          best: true,
          button: "Experimente Grátis",
          list: [
            "10gb espaço",
            "1 utilizador",
            "1 equipe",
            "Análise do site",
            "Pop-ups promocionais",
          ],
        },
        {
          title: "Profissional",
          subTitle: "Explicação simples",
          priceYearly: "R$ 399",
          priceMonthly: "R$ 289",
          billedYearly: "Cobrado anualmente",
          billedMonthly: "Faturado mensalmente",
          special: true,
          best: false,
          button: "Comece agora",
          list: [
            "10gb espaço",
            "4 utilizador",
            "1 equipe",
            "Lorem Ipsum",
            "Dolor Sit",
            "Análise do site",
            "Pop-ups promocionais",
            "24/7 suporte",
          ],
        },
        {
          title: "Avançado",
          subTitle: "Explicação simples",
          priceYearly: "R$ 499",
          priceMonthly: "R$ 359",
          billedYearly: "Cobrado anualmente",
          billedMonthly: "Faturado mensalmente",
          special: false,
          best: false,
          button: "Experimente Grátis",
          list: [
            "10gb espaço",
            "4 utilizador",
            "1 equipe",
            "Análise do site",
            "Pop-ups promocionais",
            "Lorem Ipsum",
            "Dolor Sit",
            "24/7 suporte",
            "Consectetur Adipiscing",
          ],
        },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    Breadcrumb,
    ActionButton,
    Item,
    BilledSwitch,
  },
  methods: {
    switchChange() {
      this.switchChecked = !this.switchChecked;
    },
  },
};
</script>
